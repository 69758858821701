<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="enttarget">
        <ul class="enttarget_tit">
            <li v-for="(i,index) in tit_list" @click="dianji_tiaozhuan(i)" :key="index"><span :class="path==i.path?'zise':''">{{i.name}}</span></li>
        </ul>
        <div class="enttarget_con">
            <router-view />
        </div>
    </div>
</template>

<script>
import { query_user_info, query_ent_sub_manager_list } from '../../api/api.js'
export default {
  name: 'enttarget',
  data () {
    return {
      tit_list: [],
      path: ''
    }
  },
  mounted () {
  },
  created () {
    this.path = this.$route.path
    this.lujing(this.$route.path)
    this.jichu()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      deep: true
    }
  },
  methods: {
    // 
    lujing (path) {
      // if(path=='/enttarsale'){
      //     this.dangqian='0'
      // }else if(path=='/enttarproduct'){
      //     this.dangqian='1'
      // }else if(path=='/enttarcount'){
      //     this.dangqian='2'
      // }
      this.path = path
    },
    dianji_tiaozhuan (i) {
      // if(index=='0'&&this.path!='/enttarsale'){
      //     this.$router.push('/enttarsale')
      // }else if(index=='1'&&this.path!='/enttarproduct'){
      //     this.$router.push('/enttarproduct')
      // }else if(index=='2'&&this.path!='/enttarcount'){
      //     this.$router.push('/enttarcount')
      // }
      console.log(i.path)
      this.$router.push(i.path)
    },
    jichu () {
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body.staff_level == 1) {
            this.tit_list = [
              {
                name: '销售',
                path: '/enttarsale'
              }
              // {
              //     name:'产品',
              //     path:'/enttarproduct'
              // },
              // {
              //     name:'核算',
              //     path:'/enttarcount'
              // },
            ]
          } else {
            query_ent_sub_manager_list({
              data: {
                ent_id: this.$ent_id()
              }
            }).then(rbs => {
              console.log(rbs)
              if (rbs.data.code == 200) {
                const data = JSON.parse(rbs.data.body.data)
                console.log(data)
                let date = ''
                data.forEach(item => {
                  if (item.staff_id == this.$jichuxinxi().user_id) {
                    date = item
                  }
                })
                console.log(date)
                const mod_list = date.module_id.split(',')
                let xiaoshou = false
                let chanpin = false
                let hesuan = false
                mod_list.forEach(item => {
                  if (item == '1-2') {
                    xiaoshou = true
                  }
                  if (item == '1-3') {
                    chanpin = true
                  }
                  if (item == '1-4') {
                    hesuan = true
                  }
                })
                const zhanshi_list = []
                if (xiaoshou) {
                  zhanshi_list.push({
                    name: '销售',
                    path: '/enttarsale'
                  })
                }
                // if(chanpin){
                //     zhanshi_list.push({
                //         name:'产品',
                //         path:'/enttarproduct'
                //     })
                // }
                // if(hesuan){
                //     zhanshi_list.push({
                //         name:'核算',
                //         path:'/enttarcount'
                //     })
                // }
                this.tit_list = zhanshi_list
              }
            })
          }
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './quanxian.scss';
</style>
